'use client';
import ImageWrapper from '@components/Image';
import CustomMarker from '@components/Map/CustomMarker';
import { Typography } from '@components/Typography';
import { MarkerPin01 } from '@untitled-ui/icons-react';
import clsx from 'clsx';
import { maxBy, minBy } from 'lodash';
import 'mapbox-gl/dist/mapbox-gl.css';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Map, { MapRef } from 'react-map-gl';

export interface PinProps {
  index: number;
}
export interface MarkerData {
  show?: boolean;
  lat: number;
  lon: number;
  name?: string;
  description?: string;
  image?: string;
  link?: string;
  type?: string;
}

export interface DynamicMapProps {
  items: MarkerData[];
  overlayTitle?: string;
  height?: string;
  hideOverlay?: boolean;
  hideInfo?: boolean;
  icon?: boolean;
  className?: string;
}

const Marker = dynamic(
  () => import('react-map-gl').then((module) => module.Marker),
  {
    ssr: false,
  }
);
const NavigationControl = dynamic(
  () => import('react-map-gl').then((module) => module.NavigationControl),
  {
    ssr: false,
  }
);
const Popup = dynamic(
  () => import('react-map-gl').then((module) => module.Popup),
  {
    ssr: false,
  }
);

// Get the min and max bounds of a group of markers
const getMinOrMax = (
  markers: any,
  minOrMax: 'max' | 'min',
  latOrLng: 'lat' | 'lon'
) => {
  if (minOrMax === 'max') {
    return (maxBy(markers, (value) => value[latOrLng]) as any)[latOrLng];
  } else {
    return (minBy(markers, (value) => value[latOrLng]) as any)[latOrLng];
  }
};

export const DynamicMap: React.FC<DynamicMapProps> = ({
  items,
  overlayTitle,
  height = 'h-80',
  hideOverlay,
  hideInfo,
  icon,
  className,
}) => {
  const mapRef = useRef<MapRef>();

  const [popupInfo, setPopupInfo] = useState<MarkerData>({
    lon: 152.75,
    lat: -26.59,
  });
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(-1);

  // Get the current bounds of all the markers so we can set the default zoom
  const getBounds = useCallback((markers: MarkerData[]) => {
    const maxLat = getMinOrMax(markers, 'max', 'lat');
    const minLat = getMinOrMax(markers, 'min', 'lat');
    const maxLng = getMinOrMax(markers, 'max', 'lon');
    const minLng = getMinOrMax(markers, 'min', 'lon');

    const southWest = [minLng, minLat];
    const northEast = [maxLng, maxLat];
    return [southWest, northEast];
  }, []);

  // Zoom and center a selected marker
  const flyToLocation = useCallback(
    (item, index) => {
      const itemBounds = getBounds([{ lat: item.lat, lon: item.lon }]);

      //map.flyTo({ center: [item.lat, item.lon] });
      mapRef.current.fitBounds(
        [
          [itemBounds[0][0], itemBounds[0][1]],
          [itemBounds[1][0], itemBounds[1][1]],
        ],
        {
          padding: { top: 40, bottom: 40, left: 40, right: 40 },
          duration: 1000,
          maxZoom: 16,
        }
      );

      setPopupInfo(item);
      setTimeout(() => setShowPopup(true), 800);
      setSelectedItemIndex(index);
    },
    [getBounds]
  );

  const bounds = getBounds(items);

  // Get all the marker details
  const markers = useMemo(
    () =>
      items.map((item, index) => {
        if (!item.lon || !item.lat) {
          return null;
        }

        return (
          <Marker
            key={`marker-${index}`}
            longitude={item.lon}
            latitude={item.lat}
            anchor="right">
            <div
              className={clsx(
                'group/marker flex cursor-pointer items-start rounded-md border-2 shadow-lg transition-all delay-100',
                icon ? 'p-1' : 'px-3 py-2',
                selectedItemIndex === index
                  ? 'border-brand-mothernature bg-brand-mothernature text-white'
                  : 'border-brand-mothernature bg-white text-brand-mothernature hover:bg-brand-mothernature hover:text-white'
              )}
              onClick={() => {
                flyToLocation(item, index);
              }}>
              <div className="flex flex-row items-start space-x-1">
                <MarkerPin01
                  className="hidden size-4 md:block"
                  role="presentation"
                />
                <div className="-mt-0.5 flex flex-col">
                  <CustomMarker icon={icon} item={item} />

                  {selectedItemIndex === index && (
                    <Link href={item.link} className="hover:underline">
                      <p className="text-xs">Visit Place Page</p>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </Marker>
        );
      }),
    [items, selectedItemIndex, icon, flyToLocation]
  );

  // Set the overlay, if its needed
  const mapOverlay = !hideOverlay && items.length > 1 && (
    <div className="pointer-events-none absolute inset-1 z-10 md:inset-4">
      <div className="pointer-events-auto max-w-48 flex-col rounded-md bg-white py-2 md:flex md:max-w-60 md:transform-none md:p-2 md:shadow">
        <Typography tag="h5" size="text-base" className="pb-4 pl-2 pt-2">
          {overlayTitle}
        </Typography>
        <div className="max-h-56 overflow-y-scroll">
          <div className="grid grid-cols-1 gap-y-1">
            {items.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    setShowPopup(false);
                    flyToLocation(item, index);
                    setSelectedItemIndex(index);
                  }}
                  key={`overlay-${index}`}
                  className={clsx(
                    {
                      'bg-neutral-50': selectedItemIndex === index,
                    },
                    'flex cursor-pointer items-center space-x-2 rounded-xl p-1 transition-all duration-100 ease-in-out hover:bg-neutral-50 active:bg-neutral-100 md:space-x-2'
                  )}>
                  {item.image && (
                    <ImageWrapper
                      src={item.image}
                      alt={item?.name ? item.name : ''}
                      cover
                      dimensions={{ width: 100, height: 100 }}
                      className="relative h-10 w-12 shrink-0 overflow-hidden rounded-lg"
                      imgClassName="rounded-sm"
                    />
                  )}
                  <Typography tag="p" size="text-sm">
                    {item.name}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div
        className={clsx(
          'relative w-full overflow-hidden rounded-lg',
          height,
          className
        )}>
        <Map
          mapLib={import('mapbox-gl')}
          mapStyle="mapbox://styles/mapbox/outdoors-v12"
          mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_KEY}
          scrollZoom={false}
          attributionControl={false}
          ref={mapRef}
          initialViewState={{
            longitude: bounds[0][0],
            latitude: bounds[1][1],
            zoom: 14,
          }}
          onLoad={() => {
            mapRef?.current?.fitBounds(
              [
                [bounds[0][0], bounds[0][1]],
                [bounds[1][0], bounds[1][1]],
              ],
              {
                padding: { top: 40, bottom: 40, left: 40, right: 40 },
                duration: 1000,
                maxZoom: 14,
                offset: hideOverlay ? [0, 0] : [100, -10],
              }
            );
          }}
          // transitionDuration={2000}
          // transitionInterpolator={new FlyToInterpolator()}>
        >
          {mapOverlay}
          <>
            <NavigationControl position="bottom-right" />
            {markers}
            {!hideInfo && showPopup && popupInfo.lat && (
              <Popup
                latitude={popupInfo.lat}
                longitude={popupInfo.lon}
                closeOnClick={false}
                onClose={() => setShowPopup(false)}
                closeButton={false}
                //offsetTop={20}
                //offsetLeft={15}
                anchor="bottom"></Popup>
            )}
          </>
        </Map>
      </div>
    </>
  );
};

export default DynamicMap;
