export const iconMappings = {
  1000: {
    day: 'wi-day-sunny',
    night: 'wi-night-clear',
  },
  1003: {
    day: 'wi-day-cloudy',
    night: 'wi-night-alt-cloudy',
  },
  1006: {
    day: 'wi-cloud',
    night: 'wi-night-alt-cloudy',
  },
  1009: {
    day: 'wi-cloud',
    night: 'wi-cloud',
  },
  1030: {
    day: 'wi-fog',
    night: 'wi-fog',
  },
  1063: {
    day: 'wi-day-rain-mix',
    night: 'wi-night-rain-mix',
  },
  1066: {
    day: 'wi-day-snow',
    night: 'wi-night-snow',
  },
  1069: {
    day: 'wi-day-sleet',
    night: 'wi-night-alt-sleet',
  },
  1072: {
    day: 'wi-hail',
    night: 'wi-hail',
  },
  1087: {
    day: 'wi-day-thunderstorm',
    night: 'wi-night-alt-thunderstorm',
  },
  1114: {
    day: 'wi-day-snow',
    night: 'wi-night-alt-snow',
  },
  1117: {
    day: 'wi-day-snow-wind',
    night: 'wi-night-alt-snow-wind',
  },
  1135: {
    day: 'wi-day-fog',
    night: 'wi-night-fog',
  },
  1147: {
    day: 'wi-day-fog',
    night: 'wi-night-fog',
  },
  1150: {
    day: 'wi-sprinkle',
    night: 'wi-sprinkle',
  },
  1153: {
    day: 'wi-sprinkle',
    night: 'wi-sprinkle',
  },
  1168: {
    day: 'wi-sprinkle',
    night: 'wi-sprinkle',
  },
  1171: {
    day: 'wi-sprinkle',
    night: 'wi-sprinkle',
  },
  1180: {
    day: 'wi-day-showers',
    night: 'wi-night-alt-showers',
  },
  1183: {
    day: 'wi-day-showers',
    night: 'wi-night-alt-showers',
  },
  1186: {
    day: 'wi-day-showers',
    night: 'wi-night-alt-showers',
  },
  1189: {
    day: 'wi-day-rain',
    night: 'wi-night-alt-rain',
  },
  1192: {
    day: 'wi-day-rain',
    night: 'wi-night-alt-rain',
  },
  1195: {
    day: 'wi-day-rain',
    night: 'wi-night-alt-rain',
  },
  1198: {
    day: 'wi-day-rain-mix',
    night: 'wi-night-alt-rain-mix',
  },
  1201: {
    day: 'wi-day-rain',
    night: 'wi-night-alt-rain',
  },
  1204: {
    day: 'wi-day-sleet',
    night: 'wi-night-alt-sleet',
  },
  1207: {
    day: 'wi-day-sleet',
    night: 'wi-night-alt-sleet',
  },
  1210: {
    day: 'wi-day-snow',
    night: 'wi-night-alt-snow',
  },
  1213: {
    day: 'wi-day-snow',
    night: 'wi-night-alt-snow',
  },
  1216: {
    day: 'wi-day-snow',
    night: 'wi-night-alt-snow',
  },
  1219: {
    day: 'wi-day-snow',
    night: 'wi-night-alt-snow',
  },
  1222: {
    day: 'wi-day-snow',
    night: 'wi-night-alt-snow',
  },
  1225: {
    day: 'wi-day-snow',
    night: 'wi-night-alt-snow',
  },
  1237: {
    day: 'wi-hail',
    night: 'wi-hail',
  },
  1240: {
    day: 'wi-day-showers',
    night: 'wi-night-alt-showers',
  },
  1243: {
    day: 'wi-day-showers',
    night: 'wi-night-alt-showers',
  },
  1246: {
    day: 'wi-day-storm-showers',
    night: 'wi-night-alt-storm-showers',
  },
  1249: {
    day: 'wi-day-rain-mix',
    night: 'wi-night-alt-rain-mix',
  },
  1252: {
    day: 'wi-day-rain-mix',
    night: 'wi-night-alt-rain-mix',
  },
  1255: {
    day: 'wi-day-rain-mix',
    night: 'wi-night-alt-rain-mix',
  },
  1258: {
    day: 'wi-day-rain-mix',
    night: 'wi-night-alt-rain-mix',
  },
  1261: {
    day: 'wi-hail',
    night: 'wi-hail',
  },
  1264: {
    day: 'wi-hail',
    night: 'wi-hail',
  },
  1273: {
    day: 'wi-day-thunderstorm',
    night: 'wi-night-alt-thunderstorm',
  },
  1276: {
    day: 'wi-day-thunderstorm',
    night: 'wi-night-alt-thunderstorm',
  },
  1279: {
    day: 'wi-day-snow-thunderstorm',
    night: 'wi-night-alt-snow-thunderstorm',
  },
  1282: {
    day: 'wi-day-snow-thunderstorm',
    night: 'wi-night-alt-snow-thunderstorm',
  },
};
