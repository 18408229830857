import(/* webpackMode: "eager" */ "/vercel/path0/components/AiAssistant.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Carousel/Carousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Favourite.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Image.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Map/DynamicMap.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Search/InnerSearch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs","TabsList","TabsTrigger","TabsContent"] */ "/vercel/path0/components/Tabs.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Video.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/WeatherInfo/WeatherInfo.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/tripadvisor/star.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/tripadvisor/tripadvisor.svg");
