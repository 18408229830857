'use client';
import ImageWrapper from '@components/Image';
import { Typography } from '@components/Typography';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';

import { iconMappings } from './iconMappings';

export interface WeatherInfoProps {
  lon: number;
  lat: number;
  className?: string;
}

export const WeatherInfo: React.FC<WeatherInfoProps> = ({
  lon,
  lat,
  className,
}) => {
  const [weatherData, setWeatherData] = useState<any>(null);

  const fetchWeather = useCallback(async () => {
    try {
      const response = await fetch(
        `https://api.weatherapi.com/v1/forecast.json?key=f727d59abeb1423cbf151024202311&q=${lat},${lon}&days=2`,
        { method: 'GET' }
      );
      const data = await response.json();
      const todayIcon =
        iconMappings[data.current.condition.code][
          data.current.is_day ? 'day' : 'night'
        ];
      const tomorrowIcon =
        iconMappings[data.forecast.forecastday[0].day.condition.code]['day'];
      setWeatherData({
        today: {
          text: data.forecast.forecastday[0].day.condition.text,
          temp: data.forecast.forecastday[0].day.maxtemp_c,
          icon: data.forecast.forecastday[0].day.condition.icon,
          newIcon: todayIcon,
        },
        tomorrow: {
          text: data.forecast.forecastday[1].day.condition.text,
          temp: data.forecast.forecastday[1].day.maxtemp_c,
          icon: data.forecast.forecastday[1].day.condition.icon,
          newIcon: tomorrowIcon,
        },
      });
    } catch (e) {
      console.log('Error:', e);
    }
  }, [lat, lon]);

  useEffect(() => {
    fetchWeather();
  }, [fetchWeather]);

  let TodayIcon;
  let TomorrowIcon;
  if (weatherData) {
    TodayIcon = `/icons/weather/${weatherData.today.newIcon}.svg`;
    TomorrowIcon = `/icons/weather/${weatherData.tomorrow.newIcon}.svg`;
  }

  const weatherColClasses = 'flex flex-row items-center space-x-2';

  return (
    <div className={clsx('flex h-10 space-x-12 md:gap-x-4', className)}>
      {weatherData && (
        <>
          <div className={weatherColClasses}>
            <Typography
              tag="p"
              size="text-xs"
              weight="font-semibold"
              className="text-gray-500">
              TODAY
            </Typography>
            <div className="flex flex-col md:flex-row md:items-center md:space-x-2">
              <div className="flex flex-row space-x-1">
                {TodayIcon && (
                  <ImageWrapper
                    className="relative size-8 bg-transparent"
                    src={TodayIcon}
                    alt="Today"
                  />
                )}
                <Typography
                  tag="p"
                  weight="font-semibold"
                  size="text-xl md:text-lg"
                  className="lowercase text-brand-mothernature">
                  {weatherData.today.temp}°
                </Typography>
              </div>
              {/* <Typography
                tag="p"
                weight="font-normal"
                className="capitalize italic">
                {weatherData.today.text}
              </Typography> */}
            </div>
          </div>
          <div className={weatherColClasses}>
            <Typography
              tag="p"
              size="text-xs"
              weight="font-semibold"
              className="text-gray-500">
              TOMORROW
            </Typography>
            <div className="flex flex-col">
              <div className="flex flex-row space-x-1">
                {TomorrowIcon && (
                  <ImageWrapper
                    className="relative size-8 bg-transparent"
                    src={TomorrowIcon}
                    alt="Today"
                  />
                )}
                <Typography
                  tag="p"
                  weight="font-semibold"
                  size="text-xl md:text-lg"
                  className="lowercase text-brand-mothernature">
                  {weatherData.tomorrow.temp}°
                </Typography>
              </div>
              {/* <Typography tag="p" weight="font-normal" className="capitalize">
                {weatherData.tomorrow.text}
              </Typography> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default WeatherInfo;
