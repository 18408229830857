import { MarkerData } from '@components/Map/DynamicMap';
import { Typography } from '@components/Typography';
import { ReactComponent as CalendarIcon } from '/public/icons/marker/calendar.svg';
import { ReactComponent as HotelIcon } from '/public/icons/marker/hotel.svg';
import { ReactComponent as PinIcon } from '/public/icons/marker/pin.svg';
import { ReactComponent as RestaurantIcon } from '/public/icons/marker/restaurant.svg';
import { ReactComponent as TourIcon } from '/public/icons/marker/tour.svg';

interface MarkerProps {
  item: MarkerData;
  icon?: boolean;
}

const IconComponents = {
  ACCOMM: HotelIcon,
  EVENT: CalendarIcon,
  ATTRACTION: PinIcon,
  RESTAURANT: RestaurantIcon,
  TOUR: TourIcon,
};

export const CustomMarker: React.FC<MarkerProps> = ({ item, icon }) => {
  const Icon = IconComponents[item.type] || TourIcon;

  return (
    <>
      {icon ? (
        <Icon className="size-4" />
      ) : (
        <div>
          <Typography
            tag="p"
            weight="font-semibold"
            className="flex items-center">
            {item.name}
          </Typography>
        </div>
      )}
    </>
  );
};
export default CustomMarker;
